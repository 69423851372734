<!--
 * @Descripttion: 
 * @version: 
 * @Author: kBank
 * @Date: 2023-04-27 15:54:20
-->
<template>
  <el-dialog :visible.sync="centerDialogVisible"
             width="30%"
             :show-close="false"
             :close-on-click-modal="false"
             :close-on-press-escape="false"
             center>
    <span>
      <div class="xieyi">
        <div v-for="(item, index) in agreementList.waresExtList"
             :key="index"
             v-show="item.isForcedRead == 0">
          <div style="text-align: center; color: #333">
            {{ item.title }}
          </div>
          <div v-html="item.content"></div>
        </div>
      </div>
    </span>
    <span slot="footer"
          class="dialog-footer">
      <el-button v-if="readTime > 0"
                 type="info"
                 disabled>{{$t('w_0210')}}({{ readTime }})s</el-button>
      <el-button type="primary" class="redBtn"
                 v-else
                 @click="toAgree">{{$t('w_0210')}}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import * as api from '@/api/register.js'
export default {
  data() {
    return {
      centerDialogVisible: false,
      agreementList: {},
      readTime: 0,
      // SP00014
    }
  },
  watch: {
    waresCodeList: {
      deep: true,
      handler(n) {
        this.getAgreeMent(n)
      },
    },
  },
  props: {
    waresCodeList: [],
  },
  methods: {
    toAgree() {
      this.centerDialogVisible = false
    },
    getAgreeMent(arr) {
      api
        .waresAgreement({
          waresCodeList: arr,
        })
        .then((res) => {
          this.agreementList = res.data
          this.readTime = res.data.alertTime
          if (this.readTime > 0) {
            this.centerDialogVisible = true
            this.timeInterval()
          }
        })
    },
    timeInterval() {
      this.hasQyj = 0
      let that = this
      setInterval(() => {
        if (that.readTime > 0) {
          that.readTime--
        }
      }, 1000)
    },
  },
}
</script>

<style lang="scss" scoped>
.redBtn{
  background: #d61820;
  border-color: #d61820;
}
</style>