<!--
 * @Descripttion: 
 * @version: 
 * @Author: kBank
 * @Date: 2023-01-31 15:15:10
-->
<!--
 * @Descripttion: 
 * @version: 
 * @Author: kBank
 * @Date: 2022-07-12 14:47:45
-->
<template>
  <div class="agreementBox flex_ac">
    <div class="agreementIcon">
      <el-checkbox v-model="toShow"
                   @change="toCheck">
      </el-checkbox>
      <div class="agreementText flex">
        <span>
          <span>{{ $t('w_0207') }}</span>
          <span class="agreementDetail"
                @click="popupShow = true">《{{ $t('w_0208') }}》</span>
        </span>
      </div>
    </div>
    <el-dialog class="pop"
               :append-to-body="true"
               :visible.sync="popupShow">
      <div class="pop_box" v-if="userInfo.pkCountry == 1">
        <div class="pop_title">会员协议</div>
        <h3>特别提示：</h3>
        <h4>
          青岛海之圣生物工程有限公司（以下简称“海之圣”）在此特别提醒您在正式成为会员之前，请认真阅读本《海之圣会员协议》（以下简称“本协议”），确保您充分理解本协议中各条款。请您审慎阅读并选择接受或不接受本协议。除非您接受本协议所有条款，否则您无权注册、登录或使用本协议所涉服务,无法成为海之圣的会员，无法享受会员的任何权益。您的点击同意、注册通过、使用本公司网站等行为将视为对本协议的接受，视为同意接受本协议各项条款的约束。
        </h4>
        <h4>
          本协议约定海之圣与会员之间关于海之圣产品服务（以下简称“服务”）的权利义务。“会员”是指注册并使用本服务的个人。海之圣可为会员提供相关会员增值服务来提升会员体验。本协议可由海之圣随时更新，会员可在本网站查阅最新版协议条款，更新后的协议条款一旦公布即代替原来的协议条款，恕不再另行通知。在海之圣修改协议条款后，如果会员不接受修改后的条款，请立即停止使用海之圣提供的服务，会员继续使用海之圣提供的服务将被视为接受修改后的协议。
        </h4>
        <h3>一、注册成为海之圣会员须知悉以下内容：</h3>
        <h4>1．申请人需保证所填写内容的真实性，并承诺承担因此而引起的责任；</h4>
        <h4>2．申请成为会员须同时提交本人的中国居民身份证复印件；</h4>
        <h4>
          3．会员保证符合直销法律法规的规定及海之圣对会员加入的要求，年满18周岁，并非无民事行为能力或者限制民事行为能力的人员、全日制在校学生、教师、医务人员、公务员、现役军人、直销企业的正式员工、境外人员及法律、行政法规规定不得从事兼职的其他人员；同时，申请成为会员应遵纪守法、无刑事故意犯罪记录，且不属于我国政府禁止的任何邪教和非法组织成员；违反《直销管理条例》中关于经销商身份的禁止性规定，即同时违反本条款的约定，该申请人签署的本协议自始无效，申请人不享有成为会员的任何合法权益。
        </h4>
        <h4>
          4．会员需准确提供真实有效个人信息及账户信息资料，海之圣将通过申请人提供的账户发放销售报酬及退款。若会员未能及时提供符合要求的银行账户及相关资料的，可能导致报酬或退款延迟发放，这将由会员本人自行承担相应的法律后果，且不得因此问责海之圣；
        </h4>
        <h4>
          5．会员应当自注册日起90日内需完成经销商升级，在规定时间内未完成申请成为经销商的会员，公司停止受理奖金币提现业务，且无法享受相关政策。
        </h4>
        <h4>
          6．海之圣善意提醒所有注册成功的会员按照各自实际承受能力购买及推销产品，严格遵循各项会员管理规定；
        </h4>
        <h4>
          7．海之圣依法保护会员的个人信息安全；海之圣只认可经过核准的账户注册为会员，不认可代为注册、代为登陆、代为购买产品、代为经营等各种理由，也不认可一个账户的多人共同所有或共同经营。
        </h4>
        <h4>8．申请成为会员者需按照公司规定完成会员培训、考试及领证手续；</h4>
        <h4>9．海之圣保留最终是否接受您申请成为会员的权利。</h4>
        <h3>二、网站服务内容</h3>
        <h3>本服务的具体内容由海之圣根据实际情况提供，包括但不限于：</h3>
        <h4>1．海之圣系列产品的介绍；</h4>
        <h4>2．授权会员通过其账号进行产品购买；</h4>
        <h4>3．会员对于一定时段内产品购买记录的查询；</h4>
        <h4>4．海之圣可以对其网站提供的服务予以变更。</h4>
        <h3>三、账户管理</h3>
        <h4>
          1．会员有责任妥善保管本人注册账号信息及账号密码的安全，因会员保管不善导致遭受盗号或密码失窃，责任由会员本人自行承担。会员需要对注册账号以及密码下的行为承担法律责任。会员同意在任何情况下不使用其他会员的账号或密码，也不得让其他会员使用自己的会员账户及密码。在会员怀疑他人使用其账号或密码时，会员需立即通知海之圣，否则由此造成的一切后果，由会员承担。
        </h4>
        <h4>
          2．会员应遵守本协议的各项条款，正确、适当地使用本服务，如因会员违法或违反本协议中的任何条款，海之圣有权依据本协议中断或终止对违约会员账号提供服务，而后通知会员。
        </h4>
        <h3>四、风险承担</h3>
        <h4>
          1．会员理解并同意，会员必须为自己注册账号下的一切行为负责，包括会员在购物平台上的操作以及由此产生的任何后果。会员应对服务中的内容自行加以判断，并承担因使用内容而引起的所有风险，包括因对内容的正确性、完整性或实用性的依赖而产生的风险。海之圣无法且不会对因会员个人行为而导致的任何损失或损害承担责任。
        </h4>
        <h4>
          2．如果会员发现任何人违反本协议约定或以其他不当的方式使用本服务，请立即向海之圣举报或投诉，举报或投诉电话为<span style="color: red">4001112818</span>，海之圣将依本协议约定进行处理。
        </h4>
        <h4>
          3．会员理解并同意，因业务发展需要，海之圣保留单方面对本公司、网站等服务的全部或部分服务内容变更、暂停、终止或撤销的权利，会员不同意即视为退出，需承担因此带来的风险。
        </h4>
        <h3>五、法律责任</h3>
        <h4>
          1．基于网站平台的法定责任，如果海之圣发现或收到他人举报或投诉会员违反本协议约定的，海之圣有权随时对相关会员户籍进行审查、删除，并视情节轻重对违规账号处以包括但不限于警告、账号封禁、功能封禁的处罚，且可能公示处理结果。
        </h4>
        <h4>
          2．因违反会员协议被封禁的会员，可向海之圣客服热线：<span style="color: red">4001112818</span>相关人员提交申诉，海之圣将对申诉进行审查，并自行合理判断决定是否变更处罚措施。
        </h4>
        <h4>
          3．会员应在会员权益内遵守海之圣公司相关业务及会员管理规定，会员在会员权益外的个人行为需遵守中华人民共和国各项法律法规。会员需理解并同意，海之圣有权依合理判断对违反有关法律法规或本协议规定的行为进行处理，对违法违规的任何会员采取适当的法律行动，并依据法律法规保存有关信息向有关部门报告等，会员应承担由此而产生的一切法律责任，如由此给海之圣造成损失，应承担赔偿责任。
        </h4>
        <h4>
          4．会员理解并同意，因会员违反本协议约定，导致或产生的任何第三方主张的任何索赔、要求或损失，包括合理的律师费，会员应当赔偿海之圣并使之免受损害。
        </h4>
        <h4>
          5．会员理解并同意，法律规定之外的任何利益诉求均无法得到海之圣的支持，因会员的这些诉求不符合法律规定、行业惯例及海之圣的规则，在会员得到海之圣的解释说明后仍然固执请求的，海之圣有权向国家信用管理部门申报该会员的信用缺失状况，并有权向社会公布。
        </h4>
        <h3>六、不可抗力及其他免责事由</h3>
        <h4>
          1．会员理解并确认，在使用本服务的过程中，可能会遇到不可抗力等风险因素，使本服务发生中断。不可抗力是指不能预见、不能克服并不能避免且对一方或双方造成重大影响的客观事件，包括但不限于自然灾害如洪水、地震、瘟疫流行和风暴等以及社会事件如战争、动乱、政府行为等。出现上述情况时，海之圣将努力在第一时间与相关单位配合，及时进行修复，但是由此给会员或第三方造成的损失，海之圣及合作单位在法律允许的范围内免责。
        </h4>
        <h4>
          2．会员理解并确认，在使用本服务过程中存在来自非海之圣的任何他人的包括误导性的、欺骗性的、威胁性的、诽谤性的、令人反感的或非法的信息，或侵犯他人权利的匿名或冒名的信息，以及伴随该等信息的行为，因此导致的会员或第三方的任何损失，海之圣不承担任何责任。
        </h4>
        <h4>
          3．海之圣依据法律法规、本协议约定获得处理违法违规或违约内容的权利，该权利不构成海之圣的义务或承诺，海之圣不能保证及时发现违法违规或违约行为或进行相应处理。
        </h4>
        <h3>七、服务的变更、中断、终止</h3>
        <h4>
          1．会员同意海之圣有权随时变更、中断或终止部分或全部的服务。海之圣变更、中断或终止的服务，海之圣应当在变更、中断或终止之前通知会员，并应向受影响的会员提供等值的替代性的服务。
        </h4>
        <h4>
          2．如发生下列任何一种情形，海之圣有权变更、中断或终止向会员提供的免费服务或收费服务，而无需对会员或任何第三方承担任何责任：
        </h4>
        <h4>
          1)
          根据法律规定会员应提交真实信息，而会员提供的个人资料不真实、或与注册时信息不一致又未能提供合理证明；
        </h4>
        <h4>2) 会员违反相关法律法规或本协议的约定；</h4>
        <h4>
          3) 按照法律规定或有权机关的要求，但需遵守法律法规规定而后通知会员；
        </h4>
        <h4>4) 出于安全的原因或其他必要的情形。</h4>
        <h3>八、会员权利</h3>
        <h4>
          1．会员有权根据其本人直接向消费者销售产品的业绩获得相应报酬。会员应根据公司书面文件获取海之圣的准确信息，海之圣不对个别人解读及传达公司政策的偏差负责。
        </h4>
        <h4>
          2．会员购买公司产品，有权按照《经销商退换货管理制度》对所获买商品享有退换货权益。
        </h4>
        <h4>
          3．会员发现其本人在海之圣的相关权益如身份证复印件、会员号等受到侵害，有权向海之圣提出要求处理的请求。
        </h4>
        <h4>
          4．会员发现其他会员违反法律、法规及海之圣制定的各项守则、制度规定及危害海之圣利益的行为，有权向海之圣举报和投诉，海之圣希望提出书面材料并详细说明被举报人信息。
        </h4>
        <h4>
          5．会员有权享受海之圣提供的服务，并有权参与海之圣的各项市场活动。
        </h4>
        <h4>6．会员有权参加海之圣面对正式会员提供的各类免费培训和专题讲座。</h4>
        <h4>7．会员有权销售海之圣系列产品，服务顾客。</h4>
        <h3>九、会员义务</h3>
        <h4>
          1．会员应严格遵守中华人民共和国法律、法规，履行《直销管理条例》、《禁止传销条例》、《经销商营业守则》及本协议全部规定的有关会员的所有义务。
        </h4>
        <h4>
          2．会员应保证遵守海之圣颁布的关于会员的所有活动细则、规章制度及其修正的规定。
        </h4>
        <h4>
          3．会员不应做损害海之圣名誉或非法的行为，诚实履行海之圣规章制度，不得侵害消费者权益，不得干扰公司的正常经营秩序。
        </h4>
        <h4>
          4．会员只可使用海之圣官方制作的文字、录音、影像等宣传资料开展业务活动；会员使用非法资料产生的后果由会员本人承担，与海之圣无关；海之圣拒绝一切依据非海之圣官方资料的权益主张，因此使海之圣权益遭受损失的，海之圣将依法追究其法律责任。
        </h4>
        <h4>
          5．会员应以诚信经营，透明经营的原则开展业务活动，依法经营，依法纳税。
        </h4>
        <h4>
          6．会员应保证只在本人所隶属分支机构所在的省（自治区、直辖市）已经设立服务网点的地区开展直销活动。
        </h4>
        <h4>7．会员应遵守购货及销货规定：</h4>
        <h4>1)会员不得冒用他人的名义订货；</h4>
        <h4>
          2)会员应公平交易，统一按产品标价开展销售，不得擅自收取任何附加费用，也不得有削价或变相削价等不公平竞争的行为。严禁抬价或削价转售给他人；
        </h4>
        <h4>
          3)会员应努力学习产品知识，深刻了解产品功效；严格按照海之圣提供的产品资料介绍推广产品。进行产品示范，不得对产品的用途、性能等进行夸大、失实或招人误解的虚假宣传，否则海之圣将按照《经销商营业守则》有关规定进行处理，情节严重的，应承担所引发的法律责任；
        </h4>
        <h4>
          4)未经消费者同意，会员不得进入消费者住所强行推销产品，消费者要求其停止推销活动的，应立即停止，并离开消费者住所；
        </h4>
        <h4>
          5)会员应以礼貌的语言为消费者服务，对消费者对产品的投诉应立即反馈回应，并按照海之圣规定的程序处理产品退换事宜；
        </h4>
        <h4>6)成交前，会员应向消费者详细介绍海之圣的退货制度；</h4>
        <h3>十、产品订购与报酬</h3>
        <h4>
          1．海之圣为系列产品的提供者，向购买者提供产品是海之圣的天然义务。海之圣无法逐一核实会员购买产品数量的合理性，默认所有购买者均具有合法合理的销售渠道或者消费能力。
        </h4>
        <h4>
          2．海之圣视会员订购的货款为正常消费目的，是会员基于自身需要的自主行为。会员数量巨大，订单量巨大，海之圣无法逐一核实每一笔订单支付的自愿性，默认所有支付的货款均为会员充分具备消费能力的支付行为。
        </h4>
        <h4>
          3．海之圣允许会员在购买七日内退货，详见退货制度。超过七日后海之圣无法认可退货请求具有合法性，且海之圣须遵守向市场支付销售报酬周期（七日后）之约定。会员主张货款退回的最长周期仅为购货日后七日，且需要退回合乎海之圣要求的产品。会员主张受他人蛊惑、误导而错过退货期限的，需向责任人问责。
        </h4>
        <h4>
          4．向会员支付销售报酬，依据海之圣通过商务部行业平台向社会公示的报酬制度。其他方的解释、解读、示例等均无法代表海之圣的官方。
        </h4>
        <h4>
          5．以超越实际消费承担能力等历来为海之圣反对的购买行为为基础，去获得销售报酬、促销奖励等均不符合海之圣产品销售的初衷，行为人应承担因此引起的法律责任，因此违反法律法规，给海之圣或其他会员带来损失的，行为人应依法赔偿。会员遇到其他会员类似集资、传销等非法模式操作的，有义务及时制止，并及时向海之圣举报。
        </h4>
        <h4>
          6．向会员讲解销售报酬的前瞻性陈述、过往案例及展望性示例并不构成对于会员的实质承诺，请会员自我衡量自身市场资源，特别注意经营风险。甄别对于产品及销售收益的夸大性讲解是会员长期稳健经营的基础，切勿轻信和传播。
        </h4>
        <h4>
          7．海之圣所在的领域同样面对宏观经济波动、行业监管及产业政策变化风险、行业竞争及经营模式转型等非海之圣无法避免无法克服的潜在风险，会员需要关注经营的各种风险，并准备应对措施。
        </h4>
        <h4>
          8．销售报酬的领取需正确地缴纳税收，海之圣作为代扣义务人，须履行法规赋予的法定责任。海之圣无法与任何会员探讨税收的缴纳比例或方式及其灵活性。
        </h4>
        <h3>十一、促销</h3>
        <h4>
          1．基于海之圣为生产企业，向会员出售产品，是基于生产型企业正常的销售目的；会员向本公司购买产品，公司视其为出于自身需要的自主消费产品的目的，货款与产品交付完成，视为已经达到双方的合同目的；
        </h4>
        <h4>
          2．海之圣出台促销的目的是促进零售，促进经销商向消费者、会员正常推销产品而设定辅助零售的计划。离开这一目的，仅为获得计划的奖励而参与的人，是对促销计划的恶意利用及破坏。一经发现此类非正常消费行为，公司有权终止其参与促销计划的资格；
        </h4>
        <h4>
          3．海之圣开展促销或者其他类似性质的活动，不改变前款所述购销双方的合同目的。公司所开展一切促销活动均默认参与的消费者为上述正常消费目的，仅为获得计划奖励目的的人不得主张继续获得计划奖励；
        </h4>
        <h4>
          4．海之圣促销活动的时间可以在公司认为必要时的期限截止后结束，而无需关注消费参与者消费产品之外的其他诉求。视本公司的活动为无限时间既不符合商业惯例，也不符合实际情况，因此公司无法负责给会员带来的预期利益损失的风险；
        </h4>
        <h4>
          5．海之圣拒绝接受以参与促销活动未经仔细衡量自己的经济状况和承担能力，从而影响自身正常生计等的申诉理由。会员本人须对由此而导致的一切财务损失和风险负责；
        </h4>
        <h4>
          6．海之圣与会员之间不存在促销计划正式文本之外的任何保证，不存在无损失方面的协议。任何个人解读均不代表海之圣，海之圣也无法监督到市场覆盖的全部区域。一夜暴富、长期坐享固定收益等从不是海之圣的承诺。
        </h4>
        <h3>十二、其他</h3>
        <h4>
          1．海之圣郑重提醒会员注意本协议中免除海之圣责任和限制会员权利的条款，请会员仔细阅读，自主考虑风险。
        </h4>
        <h4>
          2．本协议的效力、解释及纠纷的解决，适用于中华人民共和国法律。若会员和海之圣之间发生任何纠纷或争议，首先应友好协商解决，协商不成的，会员同意将纠纷或争议提交海之圣住所地有管辖权的人民法院管辖。
        </h4>
        <h4>
          3．本协议的任何条款无论因何种原因无效或不具可执行性，其余条款仍有效，对双方具有约束力。
        </h4>
        <h4>
          4．海之圣与会员双方均认可《中华人民共和国电子签名法》，认可依据《中华人民共和国电子签名法》赋予本协议正式的法律效力。一经点击“同意”，本协议即具有了法律赋予的合同效力。
        </h4>
        <h3 class="bold"
            style="margin-bottom: 0.1rem; margin-top: 0.4rem">
          附件一：
        </h3>
        <h4 class="bold">青岛海之圣生物工程有限公司退换货制度</h4>
        <h4 class="bold"
            style="text-indent: 0">1.0目的</h4>
        <h4>
          为了规范公司退换货服务标准，保障消费者、经销商利益，特制定本制度。
        </h4>
        <h4 class="bold"
            style="text-indent: 0">1.0目的</h4>
        <h4></h4>
        <h4 class="bold"
            style="text-indent: 0">2.0适用范围</h4>
        <h4>适用于公司所有消费者和经销商正常退换货业务处理。</h4>
        <h4 class="bold"
            style="text-indent: 0">3.0职责</h4>
        <h4>3.1总部营运中心负责受理退换货；</h4>
        <h4>3.2总部营运中心负责受理顾客投诉；</h4>
        <h4>3.3总经理直接负责管理监督顾客投诉处理情况。</h4>
        <h4 class="bold"
            style="text-indent: 0">4.0退换货管理规定</h4>
        <h4>
          4.1总部营运中心为公司指定受理退换货部门，所有退换货受理部门必须按照国家《直销管理条例》相关规定，严格执行公司退换货制度。
        </h4>
        <h4>
          4.2消费者、经销商自购买产品之日起7日（生鲜类产品收货后24小时）内，产品未开封的、包装完整，可以凭发票或者售货凭证到公司指定的退换货受理部门办理退换货。
        </h4>
        <h4>
          4.3自提出换货或者退货之日起7个工作日内，按照发票或者售货凭证标明的价款办理换货和退货。
        </h4>
        <h4 class="bold"
            style="text-indent: 0">5.0退换货受理部门</h4>
        <h4>5.1总部营运中心</h4>
        <h4 class="bold"
            style="text-indent: 0">6.0退换货程序</h4>
        <h4>
          6.1换货程序：当消费者、经销商到总部营运中心提出换货要求时，经受理人员确认符合换货条件后，将产品退回，公司予以换货处理。
        </h4>
        <h4>
          6.2退货程序：当消费者、经销商到总部营运中心提出退货要求时，经受理人员确认符合退货条件后，将产品退库，将货款全额返还。
        </h4>
        <h4 class="bold"
            style="text-indent: 0">
          7.0消费者、经销商退货处理流程
        </h4>
        <h4>
          7.1工作人员首先核对购货凭证及退货产品，确认符合退货条件的可接受退货产品；
        </h4>
        <h4>
          7.2指导退货者填写《退货申请书》，受理人员确认后将退货申请书公司留底联传至分公司，由公司进行退货申请；
        </h4>
        <h4>
          7.3
          在7个工作日内由公司办理退货手续，将退货款汇入指定账户并通知该消费者或经销商。
        </h4>
        <h4 class="footer">青岛海之圣生物工程有限公司</h4>
      </div>
      <div class="pop_box"
           v-else>
        <div class="pop_title2 pop_title1">
          KODE ETIK BAGI MITRA USAHA PT HZS INTERNATIONAL INDONESIA (HZS)
        </div>
        <h4>
          Kode Etik dan Peraturan Perusahaan ini adalah panduan dan peraturan
          baku yang berlaku bagi semua pelaku usaha Penjualan Langsung PT HZS
          INTERNATIONAL INDONESIA (selanjutnya disebut sebagai Mitra Usaha)
          dalam menjalankan bisnisnya .
        </h4>
        <h4>
          Dengan diterimanya pendaftaran pelaku usaha Penjualan Langsung oleh
          HZS, maka Mitra Usaha setuju untuk terikat dan tunduk kepada Kode Etik
          dan Peraturan ini dan bersifat mengikat antara Mitra Usaha dan
          Perusahaan (HZS). Kode Etik dan Peraturan ini menjadi satu-satunya
          ketentuan dan berlaku sampai dengan keanggotaan Mitra Usaha berakhir.
        </h4>
        <div class="pop_title2">BAB I</div>
        <div class="pop_title1">Definisi Umum</div>
        <h4>
          1.PT HZS INTERNATIONAL INDONESIA adalah suatu perseroan terbatas yang
          didirikan berdasarkan hukum Negara Republik Indonesia yang
          berkedudukan di Jakarta Barat.
        </h4>
        <h4>
          2.Mitra Usaha adalah anggota mandiri perseorangan yang terdaftar dalam
          jaringan pemasaran atau penjualan HZS yang telah mendapatkan
          persetujuan dari Perusahaan untuk menjadi Mitra Usaha melalui ajakan
          seorang sponsor.
        </h4>
        <h4>
          3.Mitra Usaha HZS bukanlah karyawan/staf PT HZS INTERNATIONAL
          INDONESIA. Seluruh karyawan PT HZS INTERNATIONAL INDONESIA dilarang
          mendaftar sebagai Mitra Usaha HZS.
        </h4>
        <h4>
          4.Sponsor adalah, MITRA yang memperkenalkan usaha HZS kepada calon
          MITRA dan kemudian secara resmi menjadi MITRA HZS.
        </h4>

        <h4>
          5.Jaringan keanggotaan adalah,semua MITRA yang menjalankan usaha HZS
          dan dalam kelompok MITRA yang bersangkutan.
        </h4>

        <h4>
          6.Konsumen adalah, MITRA pemakai produk dan pembeli akhir dari produk
          HZS dengan tujuan dipakai sendiri.
        </h4>

        <h4>
          7.Up line adalah,”atasan” MITRA atau atasannya lagi dan seterusnya
          lagi keatas.
        </h4>

        <h4>
          8.Down line adalah,MITRA dibawah Up Line,dibawahnya dan seterusnya ke
          bawah.
        </h4>

        <h4>
          9.Komisi adalah imbalan yang diberikan oleh Perusahaan kepada Mitra
          usaha yang besarnya dihitung berdasarkan hasil kerja nyata, sesuai
          volume atau nilai hasil penjualan barang, baik secara pribadi maupun
          jaringannya.
        </h4>
        <h4>
          10.Bonus atas adalah tambahan imbalan yang diberikan oleh perusahaan
          kepada mitra usaha, karena berhasil melebihi target penjualan barang
          yang ditetapkan perusahaan.
        </h4>
        <h4>
          11.Marketing Plan adalah program pemasaran yang dibuat oleh Perusahaan
          untuk mengatur perhitungan komisi, dan untuk memperoleh bonus atau
          keuntungan lainnya sesuai dengan persyaratan yang akan dicapai oleh
          Mitra Usaha dalam memasarkan produk dan atau mengembangkan jaringan.
        </h4>

        <h4>
          12.Kode Etik berarti peraturan ini, adalah suatu tatanan aturan yang
          mengikat dan menjadi pedoman bagi MITRA dalam menjalankan usaha HZS
          sejak MITRA tersebut tercatat secara resmi sebagai MITRA HZS.
        </h4>

        <h4>
          13.Produk adalah barang yang disediakan secara resmi oleh Perusahaan
          untuk dipergunakan sendiri oleh Mitra Usaha atau dijual kepada
          Konsumen dengan sistem Penjualan Langsung.
        </h4>
        <div class="pop_title2">BAB II</div>
        <div class="pop_title1">KEANGGOTAAN HZS</div>
        <h3>A.PENDAFTARAN & PERSYARATAN KEANGGOTAAN</h3>
        <h4>
          1.Pendaftaran keanggotaan dapat dilakukan oleh setiap orang dewasa
          yang berusia minimal 18 tahun, memiliki KTP, dan atau telah menikah
          bisa menjadi Mitra Usaha HZS.
        </h4>
        <h4>
          2.Setiap calon Mitra Usaha HZS wajib mengisi dan melengkapi formulir
          registrasi HZS dengan benar dan valid. Setiap Mitra Usaha HZS
          bertanggung jawab atas kebenaran isi data dirinya. PT HZS
          INTERNATIONAL INDONESIA akan dibebaskan dari tanggung jawab jika isi
          pendaftaran tersebut tidak benar.
        </h4>
        <h4>
          3.Pendaftaran dapat dilakukan dengan cara mengisi dan melengkapi
          formulir registrasi & bisa secara online lewat aplikasi , yang perlu
          dilengkapi dengan:
        </h4>
        <h4>a.Nomor ID (NIK/KTP)</h4>
        <h4>b.Nama Lengkap sesuai ID/KTP</h4>
        <h4>c.Jenis Kelamin & Tanggal Lahir</h4>
        <h4>d.Nomor Telepon & Email</h4>
        <h4>e.Alamat Sesuai ID/KTP</h4>
        <h4>f.Nomor NPWP Pribadi</h4>
        <h4>g.Data Rekening Pribadi</h4>
        <h4>
          4.Biaya pendaftaran gratis, mitra dapat mendownload e-Starterkit yang
          berisi : Brosur Produk, Marketing Plan, Kode Etik, Formulir
          Pendaftaran, Username & Password untuk akses ke web mitra.
        </h4>
        <h4>
          5.Perusahaan hanya mengakui alamat Mitra Usaha HZS sesuai yang
          tercantum pada Formulir Pendaftaran, kecuali terdapat perubahan alamat
          yang disahkan oleh Perusahaan.
        </h4>
        <h4>
          6.Setiap calon Mitra Usaha HZS yang mendaftar, harus memiliki rekening
          di bank sebagaimana yang tercantum dalam formulir registrasi HZS. Demi
          keamanan, semua komisi dan bonus akan ditransfer melalui rekening bank
          dan tidak bisa diambil secara tunai di kantor, PT HZS INTERNATIONAL
          INDONESIA tidak bertanggung jawab atas kesalahan pengisian data dan
          nomor rekening bank oleh Mitra Usaha.
        </h4>
        <h4>
          7.Semua pembayaran/transaksi calon Mitra Usaha HZS kepada HZS
          dinyatakan sah apabila dilakukan melalui transfer ke rekening HZS
          (atas nama PT HZS INTERNATIONAL INDONESIA) yang telah ditentukan, atau
          pembayaran langsung dengan bukti struk pembayaran yang disahkan oleh
          petugas PT HZS INTERNATIONAL INDONESIA. Pembayaran yang dilakukan
          tidak memenuhi ketentuan di atas adalah tidak sah dan HZS tidak
          bertanggungjawab apabila terjadi kerugian akibat transaksi tersebut.
        </h4>
        <h4>
          8.Mitra Usaha HZS bukan merupakan cabang, agen, pegawai, afiliasi
          ataupun kelompok dalam usaha patungan dari badan hukum PT HZS
          INTERNATIONAL INDONESIA, akan tetapi sebagai Pribadi yang lndependen
          atau Mitra Usaha Mandiri.
        </h4>
        <h3>B.NOMOR KEANGGOTAAN</h3>
        <h4>
          1.Peraturan mengenai keanggotaan suami istri akan diatur dalam Pasal
          tersendiri dalam Kode Etik ini.
        </h4>
        <h4>
          2.Keanggotaan Mitra Usaha berlaku 1 (satu) tahun dan dapat
          diperpanjang setiap tahunnya secara gratis dengan memiliki akumulasi
          pembelanjaan pribadi sebesar minimal 55PV sebelum masa keanggotaan
          berakhir.
        </h4>
        <h4>
          3.Dalam hal Mitra Usaha yang bersangkutan tidak memenuhi akumulasi
          pembelanjaan pribadi tersebut, maka Mitra Usaha tersebut dianggap
          tidak memperpanjang keanggotaannya dan bisa mendaftar kembali sebagai
          Mitra Usaha baru di kemudian hari.
        </h4>
        <h3>C.PERUBAHAN INFORMASI KEANGGOTAAN</h3>
        <h4>
          1.Perubahan informasi data keanggotaan dapat dilakukan dengan cara
          menghubungi Kantor Pusat PT HZS INTERNATIONAL INDONESIA bagian
          customer service, melalui email ataupun surat menyurat .
        </h4>
        <h4>
          2.Pengalihan identitas keanggotaan ataupun pertukaran keanggotaan dari
          satu jaringan ke jaringan lain tidak diperbolehkan dengan alasan
          apapun, kecuali diwariskan karena Mitra meninggal
        </h4>
        <h4>
          3.Apabila ditemukan identitas keanggotaan yang tidak jelas, atau
          terdapati keanggotaan ganda yang bisa merugikan pihak lain, maka
          perusahaan berhak menonaktifkan nomor keanggotaan yang terakhir
          didaftarkan dan secara otomatis kehilangan semua hak sebagai anggota
          HZS.
        </h4>
        <h4>
          4.Apabila ada laporan dengan bukti otentik bahwasanya Mitra Usaha HZS,
          baik dengan nama dia sendiri ataupun dengan nama lain telah mencoba
          bergabung kembali di group jaringan yang lain atau cross line tanpa
          persetujuan dari semua upline dan sponsornya, maka perusahaan akan
          menonaktifkan nomor keanggotaan tersebut tanpa kompensasi.
        </h4>

        <h3>D.KEANGGOTAAN SUAMI ISTRI</h3>
        <h4>
          1.Keanggotaan suami istri dianggap terpisah dan harus berada dalam
          satu garis sponsorisasi yang sama. Apabila melanggar, maka nomor
          keanggotaan yang terbaru akan dihapuskan oleh perusahaan tanpa
          terkecuali dan tanpa kompensasi apapun dari perusahaan.
        </h4>
        <h4>
          2.Apabila di kemudian hari terdapat dua orang Mitra Usaha HZS dari
          jaringan yang berbeda memutuskan untuk menikah, maka keanggotaan
          mereka tetap ada di dalam jaringan yang terpisah dan independen.
        </h4>
        <h4>
          3.Apabila di kemudian hari terdapat keanggotaan Mitra Usaha HZS yang
          merupakan pasangan nikah memilih untuk bercerai, maka PT HZS
          INTERNATIONAL INDONESIA akan tetap mempertahankan keanggotaan sesuai
          dengan aplikasi yang sudah ditandatangani di awal sampai ada keputusan
          pengadilan yang menyebutkan sebaliknya.
        </h4>
        <div class="pop_title2">BAB III</div>
        <div class="pop_title1">HAK DAN KEWAJIBAN ANGGOTA</div>
        <h3>A.HAK MITRA USAHA HZS</h3>
        <h4>
          1.Mitra Usaha HZS berhak mendapatkan penjelasan yang benar baik dalam
          hal informasi mengenai Perusahaan, Produk, Marketing Plan HZS, maupun
          promosi yang diadakan oleh Perusahaan.
        </h4>
        <h4>
          2.Mitra Usaha HZS berhak mendapatkan kesempatan yang sama untuk
          berprestasi dalam melakukan penjualan produk dan mendapatkan komisi
          sesuai Marketing Plan yang ditetapkan.
        </h4>
        <h4>
          3.Mitra Usaha HZS berhak memperoleh penghasilan uang, komisi, bonus,
          cashback, dan bonus rewards dari bisnis HZS berdasarkan acuan serta
          perhitungan di Marketing Plan HZS berdasarkan hasil kerja dan memenuhi
          ketentuan sesuai yang disyaratkan.
        </h4>
        <h4>
          4.Mitra Usaha HZS berhak mendapatkan produk yang bermutu sesuai dengan
          manfaat dan harga yang dibayarkan kepada Perusahaan.
        </h4>
        <h4>
          5.Mitra Usaha HZS berhak mengikuti semua kegiatan, pelatihan
          perusahaan, serta promosi sesuai dengan ketentuan Perusahaan.
        </h4>

        <h3>B.KEWAJIBAN MITRA USAHA HZS</h3>
        <h4>
          1.Mengikuti dan mematuhi semua prosedur, peraturan dan Kode Etik yang
          di tetapkan PT HZS INTERNATIONAL INDONESIA.
        </h4>
        <h4>
          2.Selalu menjaga nama baik PT HZS INTERNATIONAL INDONESIA dan tidak
          merugikan orang lain.
        </h4>
        <h4>
          3.Bersikap sopan, menghargai, menghormati dan menjunjung tinggi
          kejujuran, integritas sebagai Mitra Usaha HZS.
        </h4>
        <h4>
          4.Setiap Mitra Usaha HZS wajib menjaga kerahasiaan data loginnya
          sendiri, yaitu username dan passwordnya. Dan perusahaan tidak
          bertanggung jawab atas kelalaian yang terjadi pada Anggota tersebut.
        </h4>
        <div class="pop_title2">BAB IV</div>
        <div class="pop_title1">LARANGAN BAGI MITRA USAHA HZS</div>
        <h4>
          1.Mitra Usaha HZS dilarang memberikan keterangan menyesatkan atau over
          claim kepada khalayak ramai, dan atau informasi yang bertentangan
          dengan kebijakan atau literatur resmi dari PT HZS INTERNATIONAL
          INDONESIA.
        </h4>
        <h4>
          2.Mitra Usaha HZS dilarang menjual dengan cara paksaan atau cara lain
          yang dapt menimbulkan gangguan pada pihak lain baik secara fisik
          maupun psikis.
        </h4>
        <h4>
          3.Mitra Usaha HZS dilarang membujuk calon Mitra Usaha lain ataupun
          konsumen untuk membeli atau menimbun produk yang melebihi
          kebutuhannya.
        </h4>
        <h4>
          4.Mitra Usaha HZS dilarang menggunakan jaringan kerja PT HZS
          INTERNATIONAL INDONESIA untuk pemasaran produk-produk Penjualan
          Langsung (direct selling atau multi level marketing) lain.
        </h4>
        <h4>
          5.Mitra Usaha HZS dilarang mengganti kemasan produk ataupun merubah
          jumlah atau isi dari paket produk resmi yang sudah ditetapkan oleh PT
          HZS INTERNATIONAL INDONESIA, karena tindakan ini dapat merusak dan
          merugikan.
        </h4>
        <h4>
          6.Mitra Usaha HZS dilarang mengurangi ataupun menambah kompensasi
          program diluar yang sudah ditetapkan oleh PT HZS INTERNATIONAL
          INDONESIA. Apabila Mitra Usaha HZS ingin membuat program sendiri
          dengan tujuan ingin meningkatkan penjualan atau jaringannya, maka yang
          bersangkutan tidak diperbolehkan menggunakan logo/ kata-kata PT HZS
          INTERNATIONAL INDONESIA ataupun afiliasinya di dalam program tersebut.
        </h4>
        <h4>
          7.Mitra Usaha HZS dilarang menjual atau mengedarkan produk yang sudah
          tidak layak pakai atau tidak layak konsumsi.
        </h4>
        <h4>
          8.Mitra Usaha HZS dilarang menjual produk dibawah harga yang sudah
          ditetapkan resmi oleh PT HZS INTERNATIONAL INDONESIA. Apabila terjadi
          pelanggaran dan terdapat pelaporan dengan bukti yang cukup dan
          otentik, maka perusahaan berhak untuk memberikan surat peringatan
          hingga memberhentikan keanggotaannya, dan tanpa disertai kompensasi
          apapun.
        </h4>
        <h4>
          9.Mitra Usaha HZS dilarang menggunakan tulisan, lambang dan merek
          dagang HZS dan afiliasinya untuk brosur atau alat bantu jual lainnya
          untuk sesuatu yang tidak benar atau tidak sesuai.
        </h4>
        <h4>
          10.Mitra Usaha HZS dilarang bertindak untuk dan atas nama perusahaan,
          mewakili perusahaan melakukan pengikatan hukum dengan pihak lain
          ataupun mewakili seolah-olah dirinya adalah bagian dari struktur atau
          karyawan dari perusahaan.
        </h4>
        <h4>
          11.Mitra Usaha HZS dilarang mengklaim diri atau jaringannya untuk
          seolah-olah menguasai atau mempunyai wilayah usaha tertentu secara
          monopoli di bisnis ini.
        </h4>
        <h4>
          12.Mitra Usaha HZS dilarang memperkenalkan atau mensponsori
          staff/pegawai PT HZS INTERNATIONAL INDONESIA, termasuk orang tuanya
          dan saudara sedarah dari staff/pegawai tersebut. Apabila hal itu
          dilakukan, maka perusahaan akan menghapus keanggotaan dari yang telah
          disponsorinya, perusahaan juga berhak untuk memberhentikan Mitra Usaha
          yang melanggar tersebut dari HZS.
        </h4>
        <h4>
          13. Mitra Usaha HZS dilarang untuk berjualan produk-produk HZS melalui
          toko retail atau di lokasi eceran tetap, dan situs E-commerce atau
          Market Place seperti Shopee, Lazada, Matahari Mall, Tokopedia,
          Bukalapak, OLX, Qool0, Supermarket, dan usaha sejenis lainnya.
        </h4>
        <div class="pop_title2">BAB V</div>
        <div class="pop_title1">BERAKHIRNYA KEANGGOTAAN</div>
        <h4>
          1.Masa keanggotaan seorang di HZS dinyatakan batal atau berakhir
          apabila:
        </h4>
        <h4>
          a.Mitra Usaha yang bersangkutan tidak melakukan perpanjangan
          keanggotaan seperti pada Bab II Bagian B angka 2.
        </h4>
        <h4>
          b.Mitra Usaha yang bersangkutan membatalkan dengan cara mengundurkan
          diri terlebih dahulu dengan menyampaikan permohonan tertulis kepada
          HZS.
        </h4>
        <h4>
          c.Dihentikan keanggotaannya oleh karena terbukti melakukan pelanggaran
          Kode Etik yang telah di tentukan oleh HZS.
        </h4>
        <h4>
          d.Dihentikan keanggotaannya karena ada keputusan atau perintah dari
          Pengadilan.
        </h4>
        <h4>
          e.Dihentikan karena adanya perubahan peraturan perundang-undangan yang
          berlaku di INDONESIA.
        </h4>
        <h4>
          2.Mitra Usaha HZS yang telah membatalkan keanggotaannya tersebut,
          selanjutnya akan melepas segala bentuk keterkaitan dari seluruh
          jaringannya yang lama, termasuk terhadap seluruh komisi dan bonus
          serta pengumpulan pointnya jika ada, dan tidak ada kompensasi apapun
          yang akan digantikan oleh Perusahaan terhitung setelah tanggal
          pemberhentian.
        </h4>
        <h4>
          3.Seorang anggota atau Mitra Usaha yang sudah dibatalkan
          keanggotaannya, bisa mendaftar lagi setelah lewat dari 90 (sembilan
          puluh) hari, terkecuali yang bersangkutan termasuk dalam pelanggaran
          berat.
        </h4>
        <h4>
          4.Pelanggaran berat yang dimaksudkan dalam poin diatas adalah jika
          terlibat kasus pidana berat semisal pembunuhan, korupsi, penipuan,
          maupun terorisme, dan sebagainya dalam status yang sudah disangkakan
          maupun yang berketetapan hukum.
        </h4>
        <h4>
          5.Perusahaan tidak memberikan toleransi atas Pembajakan Jaringan atas
          seorang anggota lain yang keanggotaannya masih berlaku, jika ada
          seorang anggota mendaftarkan kembali keanggotaannya dengan menggunakan
          upline yang lain, baik karena kemauan sendiri maupun karena
          dipengaruhi orang lain, maka keanggotaan yang baru akan dibatalkan
          beserta seluruh haknya akan hangus pada keanggotaan baru tersebut.
        </h4>
        <div class="pop_title2">BAB VI</div>
        <div class="pop_title1">JAMINAN KEPUASAN</div>
        <h4>
          1.Jaminan Kepuasan (Satisfaction Guarantee), Mitra Usaha HZS berhak
          untuk melakukan penukaran produk yang telah dibeli dalam waktu 7
          (tujuh) hari, apabila ternyata mutu produk yang disampaikan tidak
          sesuai dengan yang diklaim atau dijanjikan secara tertulis oleh
          Perusahaan (sesuai yang terdapat dalam brosur/katalog resmi
          Perusahaan). Penukaran bisa dilakukan dengan melampirkan Nota
          Penjualan Resmi. Perusahaan akan menukarkan dengan produk yang sama,
          dan segala biaya pengiriman menjadi tanggungan Mitra Usaha.
        </h4>
        <h4>
          2.Apabila terbukti bahwa produk HZS yang sudah digunakan atau
          dimanfaatkan sesuai ketentuan menimbulkan kerugian fisik bagi Mitra
          Usaha, maka Perusahaan akan memberikan ganti rugi atau kompensasi atas
          kerugian tersebut dengan selayaknya. Ganti rugi tidak berlaku apabila
          kerugian yang timbul adalah akibat kelalaian dari Mitra Usaha atau
          akibat dari penggunaan produk yang tidak sesuai ketentuan.
        </h4>
        <div class="pop_title2">BAB VII</div>
        <div class="pop_title1">STATUS KEMATIAN DAN WARIS</div>
        <h4>
          1.Mitra Usaha HZS hanya dapat mewariskan hak-haknya yang melekat
          sebagai anggota kepada ahli warisnya yang sah apabila Mitra Usaha yang
          bersangkutan meninggal dunia.
        </h4>
        <h4>
          2.Ahli waris yang sah ditentukan oleh Mitra Usaha sendiri atau
          berdasarkan Hukum Waris dalam Undang-undang yang berlaku, dan atau
          melalui ketetapan Pengadilan. Perusahaan tidak berhak menentukan ahli
          waris dari Mitra Usaha yang bersangkutan.
        </h4>
        <h4>
          3.Ahli waris sah yang akan menggantikan keanggotaan Mitra Usaha
          Pewaris harus memenuhi prosedur yang ditetapkan oleh Perusahaan antara
          lain :
        </h4>
        <h4>a.Melampirkan surat wasiat atau surat keterangan kematian</h4>
        <h4>
          b.Melampirkan pernyataan dan ahli waris yang lain bila ada, yang
          isinya berupa persetujuan pewarisan keanggotaan tersebut yang
          dilampirkan diatas materai minimal Rp. 10.000 (enam ribu rupiah).
        </h4>
        <h4>c.Melampirkan fotocopy kartu keluarga terakhir.</h4>
        <h4>
          d.Mengisi form pendaftaran baru atas nama ahli waris dan diserahkan
          kepada Perusahaan.
        </h4>
        <h4>
          4.Apabila terjadi sengketa oleh pihak lain perihal kewarisan ini maka
          PT. HZS akan mengikuti keputusan akhir dari pengadilan. Selama dalam
          proses penyelesaian sengketa tersebut keanggotaan akan ditangguhkan
          sementara sampai mendapat keputusan hukum yang tetap dan komisi dan
          bonusnya akan ditahan sementara, dan akan diberikan dikemudian hari
          kepada ahli waris yang sah menurut pengadilan.
        </h4>
        <div class="pop_title2">BAB VIII</div>
        <div class="pop_title1">HAK DAN KEWAJIBAN PERUSAHAAN</div>
        <h3>A.Hak Perusahaan</h3>
        <h4>
          1.Perusahaan berhak menerima atau menolak permohonan menjadi anggota
          yang disampaikan melalui formulir pendaftaran Mitra Usaha HZS yang
          diisi oleh calon Mitra Usaha secara benar dan jujur.
        </h4>
        <h4>
          2.Demi perlindungan atas usahanya, Perusahaan berhak melakukan segala
          tindakan yang sesuai hukum atas pelanggaran yang dilakukan oleh Mitra
          Usaha HZS dalam menjalankan usahanya yang dinilai tidak mematuhi
          kebijakan-kebijakan yang telah digariskan Perusahaan.
        </h4>
        <h4>
          3.Perusahaan berhak menghentikan keanggotaan atau kerjasama bisnis
          dengan Mitra Usaha HZS dengan pemberitahuan tertulis sebelumnya.
        </h4>
        <h4>
          4.Perusahaan berhak mengadakan perubahan dan penyesuaian atas
          Marketing Plan, Promosi, dan Kode Etik dan Peraturan Perusahaan,
          dengan persetujuan dari Kementerian Perdagangan Republik Indonesia,
          dan di sosialisasikan kepada seluruh anggota selambat-lambatnya 30
          (tiga puluh) hari sebelum diberlakukan.
        </h4>

        <h3>B.Kewajiban Perusahaan</h3>
        <h4>
          1.Perusahaan berkewajiban memberikan informasi yang jelas dan benar
          kepada Mitra Usaha HZS berkaitan dengan usahanya, Marketing Plan,
          produk dan semua hal yang terkait dengan kegiatannya.
        </h4>
        <h4>
          2.Perusahaan berkewajiban mematuhi aturan yang mengacu pada ketentuan
          yang berlaku di Indonesia dalam melakukan usahanya dan pembinaan Mitra
          Usaha HZS.
        </h4>
        <h4>
          3.Perusahaan berkewajiban mengadakan produk yang baik, berkualitas,
          dan memiliki ijin edar, serta menyediakan alat-alat bantu penjualan
          yang diperlukan untuk Mitra Usaha HZS dalam menjalankan dan
          mengembangkan usahanya.
        </h4>
        <h4>
          4.Perusahaan wajib memberikan pelatihan dan pembinaan berkait dengan
          pengembangan usaha, mengadakan kegiatan dan fasilitas lainnya dalam
          rangka membantu pengembangan usaha para Mitra Usaha HZS.
        </h4>
        <h4>
          5.Perusahaan berkewajiban menjamin pembayaran komisi/bonus/reward atas
          usaha yang dilakukan oleh para Mitra Usaha HZS sesuai dengan yang
          tercantum dalam Marketing Plan.
        </h4>
        <h4>
          6.Perusahaan berkewajiban memberikan layanan sebaik mungkin kepada
          para Mitra Usaha HZS dan menjaga kondusifitas usaha bagi para
          pelakunya.
        </h4>
        <h4>
          7.Perusahaan berwajiban mengenakan pajak progresif atas penghasilan
          yang diperoleh Mitra Usaha HZS dalam menjalankan bisnisnya sesuai
          dengan ketentuan Undang-Undang Perpajakan yang berlaku di negara
          Indonesia untuk Industri Penjualan Langsung.
        </h4>
        <div class="pop_title2">BAB IX</div>
        <div class="pop_title1">KOMISI DAN BONUS</div>
        <h4>1.RINCIAN KOMISI DAN BONUS</h4>
        <h4>a.KOMISI/BONUS PLAN A</h4>
        <h4>
          i.KOMISI Sponsor adalah komisi yang anda terima berdasar belanja paket
          orang yang anda sponsori langsung.
        </h4>
        <h4>
          ii.KOMISI Group adalah komisi yang anda terima dihitung dari
          persentase PV kaki kecil anda.
        </h4>
        <h4>
          iii.Bonus Leadership adalah bonus yang anda terima sebesar 5% dari
          Komisi Grup downline anda, dari Level 1 sampai Level 10 sesuai
          Peringkat anda,
        </h4>

        <h4>b.KOMISI/BONUS PLAN B</h4>
        <h4>i.Komisi Diskon & Komisi selisih Peringkat</h4>
        <h4>
          a.Komisi Diskon adalah diskon atas belanja Repeat Order Pribadi yang
          besarnya sesuai dengan Peringkat Mitra.
        </h4>
        <h4>
          b.Komisi Perbedaan peringkat adalah diskon yang diterima Upline, bila
          downlinenya lebih rendah peringkatnya.
        </h4>
        <h4>
          ii.Bonus Sharing Bulanan adalah adalah bonus yang diambil 0,5% dari
          Omset Bulanan, dan dibagikan kepada masing-masing peringkat ( ada 10
          Peringkat )
        </h4>
        <h4>
          iii.Bonus Group Repeat Order adalah bonus yang anda terima dihitung
          dari persentase PV kaki kecil anda.
        </h4>
        <h4>
          2.Pembayaran komisi dan bonus akan dilakukan secara transfer melalui
          Bank dengan mengenakan biaya administrasi sesuai kebijakan Bank
          masing-masing. Jenis komisi dan bonus pada waktu transfernya, dengan
          pengecualian saat terjadi kondisi yang tidak memungkinkan atau force
          majeur :
        </h4>
        <div class="pop_title2">BAB X</div>
        <div class="pop_title1">Pajak</div>
        <h4>
          1.Penerimaan Komisi dan Bonus oleh mitra usaha dikenakan pajak
          mengikuti ketentuan perpajakan yang berlaku di Indonesia, dimana
          setiap mitra usaha yang mendapatkan komisi dan bonus atau bonus Reward
          akan langsung dipotong pajak penghasilan (PPh) sesuai dengan peraturan
          perpajakan yang berlaku.
        </h4>
        <h4>
          2.Bukti Potong Pajak akan diberikan kepada MITRA yang bersangkutan
        </h4>
        <h4>
          3.Segala kewajiban perpajakan dari seorang mitra usaha menjadi beban
          dari tanggung jawab mitra usaha yang bersangkutan.
        </h4>
        <div class="pop_title2">BAB XI</div>
        <div class="pop_title1">PELATIHAN DAN PEMBINAAN BAGI MITRA USAHA</div>
        <h4>1.Pelatihan yang diberikan oleh Perusahaan adalah:</h4>
        <h4>
          a.HZS Open Plan (HOP), adalah acara yang difasilitasi oleh Perusahaan
          untuk mengenalkan tentang peluang bisnis HZS kepada masyarakat umum
          sekaligus membantu para Mitra Usaha yang belum mampu melakukan
          presentasi mandiri. Acara ini dilakukan secara berkala setiap satu
          bulan sekali tiap minggu pertama tiap bulannya, bebas biaya, dan
          dilakukan di Kantor Pusat. Terbuka bagi semua peringkat.
        </h4>
        <h4>
          b.HZS Business Orientation (HBO), adalah pelatihan bagi para Mitra
          Usaha HZS yang baru bergabung untuk mendalami pengetahuan produk,
          marketing plan, cara menjual, serta cara menjalankan bisnis HZS. Acara
          ini dilakukan secara berkala setiap satu minggu sekali setiap
          bulannya, bebas biaya, dan dilakukan di Kantor Pusat. Terbuka bagi
          semua peringkat.
        </h4>
        <h4>
          c.HZS Leaders Club (HLC), adalah pertemuan khusus bagi para Mitra
          Usaha HZS yang telah mencapai peringkat tertentu sebanyak tiga kali
          dalam setahun. Pertemuan ini dilakukan secara tahunan dan tidak
          berbayar bagi para pencapainya. Hanya bagi Mitra Usaha yang telah
          mencapai peringkat Platinum.
        </h4>
        <h4>2.Pembinaan yang akan diberikan oleh Perusahaan adalah :</h4>
        <h4>
          a.HZS Champion Club (HCC), merupakan program pembinaan bagi pemula di
          bisnis HZS. Acara pembinaan sehari penuh yang diadakan oleh Perusahaan
          untuk mempersiapkan para pemula memiliki sikap seorang pengusaha dan
          keterampilan dasar dalam menjual produk maupun melakukan sponsorisasi.
          KBW akan diadakan dua bulan sekali dan tidak berbayar. Tidak ada
          syarat kualifikasi peringkat untuk mengikuti pembinaan ini.
        </h4>
        <h4>
          b.HZS STOCKIST MEETING (HSM), merupakan program pembinaan bagi seluruh
          Stokis HZS agar dapat memberikan pelayanan yang lebih baik kepada
          seluruh Mitra Usaha, sekaligus memberikan penghargaan kepada Stokis
          yang berprestasi. Stokist Meeting ini dilakukan setahun sekali di
          Kantor Pusat. Peserta adalah pemilik dan admin Stokist saja, dan tidak
          berbayar.
        </h4>
        <div class="pop_title2">BAB XII</div>
        <div class="pop_title1">
          SANKSI ATAS PELANGGARAN OLEH MITRA USAHA HZS
        </div>
        <h4>
          1.Mitra Usaha HZS yang melakukan perbuatan melanggar Kode Etik ini
          ataupun hukum, dan Perusahaan mendapatkan laporan secara tertulis
          dengan bukti yang cukup, maka perusahaan berhak menjatuhkan Surat
          Peringatan 1. Apabila melanggar untuk yang ke-2 kalinya, maka
          perusahaan berhak untuk memberhentikan/menghapus keanggotaan Mitra
          Usaha tersebut.
        </h4>
        <h4>
          2.Pelanggaran yang terjadi akan diberi surat peringatan atau dapat
          mengakibatkan diberhentikannya keanggotaan Mitra Usaha tersebut, atau
          dilakukan penundaan pembayaran komisi dan bonus sementara selama masa
          investigasi.
        </h4>
        <h4>
          3.Apabila berdasarkan hasil investigasi, Mitra Usaha yang bersangkutan
          terbukti melanggar ketentuan sebagaimana yang sudah ditetapkan, maka
          komisi dan bonus yang bersangkutan akan dibayarkan dan setelah itu
          keanggotaannya langsung dibatalkan. Sebaliknya apabila ia tidak
          terbukti melakukan pelanggaran, maka komisi dan bonus yang
          bersangkutan akan kembali diproses dan keanggotaannya tetap
          dilanjutkan.
        </h4>
        <div class="pop_title2">BAB XIII</div>
        <div class="pop_title1">PEMBAJAKAN DOWNLINE/MITRA USAHA LAIN</div>
        <h4>
          1.Mitra Usaha HZS tidak diperkenankan untuk membujuk atau membajak
          downline atau Mitra Usaha lain dari garis sponsorisasi yang berbeda
          untuk bergabung di garis sponsorisasinya. Apabila hal itu terbukti,
          maka:
        </h4>
        <h4>
          a.User Name Mitra Usaha yang baru disponsori tersebut akan dihapuskan.
        </h4>
        <h4>
          b.Sponsor yang merekrutnya akan dikenakan sanksi berupa Surat
          Peringatan hingga keanggotaan sponsor tersebut dicabut & diberhentikan
          dengan dengan ketentuan sesuai Kode Etik ini.
        </h4>
        <div class="pop_title2">BAB XIV</div>
        <div class="pop_title1">STOKIS</div>
        <h4>1.Persyaratan umum menjadi Stokist adalah sebagai berikut</h4>
        :
        <h4>
          a.Pemohon Stokist adalah Mitra Usaha dengan kualifikasi minimal
          peringkat Manager, sudah belanja Paket Diamond, dan mampu menciptakan
          omset Rp. 100.jt / bulan .
        </h4>
        <h4>
          b.Bersedia mematuhi Kode Etik Perusahaan dan peraturan stokist dari
          perusahaan, dan bersedia diberikan teguran atau sanksi apabila
          melanggar aturan.
        </h4>
        <h4>
          c.Bersedia memberikan layanan kepada semua Mitra Usaha tanpa
          membedakan jaringan.
        </h4>
        <h4>
          d.Siap melayani komplain produk dan layanan dari semua Mitra Usaha.
        </h4>
        <h4>
          e.Bersedia dilibatkan oleh perusahaan dalam acara-acara resmi
          perusahaan di wilayahnya.
        </h4>
        <h4>
          2.Besaran deposit awal sebagai Stokis HZS adalah Rp 100.000.000,-
          (seratus juta rupiah)
        </h4>
        <h4>3.Keuntungan menjadi Stokist :</h4>
        <h4>
          a.Mendapatkan pembinaan khusus dari perusahaan dalam acara tahunan
          khusus stokist. (semua pembinaan tahunan dengan akomodasi di tanggung
          perusahaan).
        </h4>
        <h4>
          b.Mendapatkan bantuan alat peraga baik brosur, pamflet, flyer, form
          kwitansi standar dari perusahaan secara berkala.
        </h4>
        <h4>
          c.Mendapatkan ongkos kirim gratis dengan pembelian nominal tertentu.
        </h4>
        <h4>
          d.Mendapatkan potongan harga (Discount) sebesar 4% dari harga produk
          setiap kali melakukan order ke perusahaan.
        </h4>
        <h4>4.Kewajiban bagi Stokis HZS :</h4>
        <h4>
          a.Stockis HZS wajib melayani setiap order dari perseorangan di area
          pemasarannya masing-masing tanpa terkecuali dan melakukan transaksi
          melalui sistem web HZS.
        </h4>
        <h4>
          b.Stokis wajib memberikan bukti transaksi yang kepada Mitra Usaha yang
          membeli produk, ataupun yang bergabung sebagai Mitra Usaha baru.
        </h4>
        <h4>
          c.Untuk order perseorangan yang memerlukan jasa kurir, Stockis HZS
          wajib menambahkan ongkos pengiriman, agar adil dan tidak terjadi
          penjualan dibawah harga resmi Perusahaan.
        </h4>
        <h4>
          d.Jumlah Repeat Order Minimum dari Stokis adalah sebesar Rp
          25.000.000,- ( lima juta rupiah) dalam sekali order untuk memperoleh
          pengiriman gratis.
        </h4>
        <div class="pop_title2">BAB XV</div>
        <div class="pop_title1">PERSELISIHAN ANTAR SPONSOR</div>
        <h4>
          1.PT HZS INTERNATIONAL INDONESIA tidak akan menengahi perselisihan
          apapun yang berasal dari satu atau beberapa individu yang menghubungi
          calon anggota (Prospek) yang sama. Jika lebih dari 1 (Satu) Mitra
          Usaha HZS yang mengklaim telah mensponsori orang yang sama, maka
          Perusahaan hanya akan mengakui atas aplikasi keanggotaan yang terlebih
          dahulu melakukan pendaftaran melalui online di Web Pusat
          www.HZS.co.id.
        </h4>
        <div class="pop_title2">BAB XVI</div>
        <div class="pop_title1">PERSELISIHAN DENGAN PERUSAHAAN</div>
        <h4>
          1.Segala bentuk perselisihan dengan perusahaan akan diselesaikan
          dengan mengutamakan prinsip musyawarah dan kekeluargaan. Namun apabila
          perselesihan tidak dapat terselesaikan dengan baik, maka penyelesaian
          akan dilakukan sesuai dengan hukum yang berlaku di Negara Kesatuan
          Republik Indonesia.
        </h4>
        <h4>
          2.Penyelesaian secara hukum akan dilakukan di Pengadilan Negeri dimana
          kedudukan PT HZS INTERNATIONAL INDONESIA berada, yakni Jakarta Barat
        </h4>
        <div class="pop_title2">BAB XVII</div>
        <div class="pop_title1">PENUTUP</div>
        <h4>
          Kode Etik dan Peraturan Perusahaan ini dibuat sebagai norma dan
          perjanjian antara PT HZS INTERNATIONAL INDONESIA dan Mitra Usaha HZS
          yang secara resmi telah diterima keanggotaannya. Setiap perubahan
          dalam Kode Etik dan Peraturan Perusahaan ini akan diberitahukan kepada
          seluruh Mitra Usaha selambatnya 30 (tiga puluh) hari sebelum
          diberlakukan dengan persetujuan terlebih dahulu dari Kementerian
          Perdagangan Republik Indonesia.
        </h4>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
export default {
  name: 'Title',
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    isShow(n, o) {
      console.log('🚀 ~ n', n)
      this.toShow = n
    },
  },
  data() {
    return {
      popupShow: false,
      isqyj: false,
      toShow: this.isShow,
      hasQyj: 0,
      agreeData: '',
      agreementTitle: '',
    }
  },
  computed: {
    ...mapGetters(['userInfo']),
  },
  methods: {
    openIsqyj(val) {
      this.agreementTitle = val
      this.isqyj = true
    },
    agreeList(val) {
      this.agreeData = val
    },
    getHasQyj(val) {
      this.hasQyj = val
    },
    toCheck(e) {
      this.$emit('agree', this.toShow)
    },
  },
}
</script>

<style lang="scss" scoped>
.agreementBox {
  width: 100%;
  height: auto;
  .agreementIcon {
    display: flex;
    // align-items: center;
    justify-content: end;
  }
  .agreementText {
    // line-height: 0.2rem;
    margin-left: 10px;
    font-size: 14px;
    max-width: 600px;
    display: flex;
    flex-wrap: wrap;
    .agreementDetail {
      color: #72deed;
      font-weight: bold;
      font-size: 14px;
      cursor: pointer;
    }
  }
}

.pop {
  z-index: 20000 !important;
  text-align: left;
  .pop_box {
    max-height: 70vh;
    overflow-y: scroll;
    padding: 0rem 0.4rem 0.8rem 0.4rem;
  }

  .pop_title {
    width: 100%;
    height: 1.4rem;
    line-height: 1.4rem;
    font-size: 16px;
    text-align: center;
  }
  .pop_title1 {
    width: 100%;
    font-size: 16px;
    text-align: center;
    margin-bottom: 20px;
    font-weight: 500;
  }
  .pop_title2 {
    width: 100%;
    font-size: 16px;
    text-align: center;
    margin-top: 20px;
    font-weight: 500;
  }
}

h3 {
  margin: 0.1rem 0;
}

h4 {
  text-indent: 0.5rem;
}

.bold {
  font-weight: bold;
}

.footer {
  margin-top: 0.4rem;
  text-align: right;
}
</style>
